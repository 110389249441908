import React, { useContext, useEffect } from "react";
import { AuthContext } from "@project/shared/utils/AuthContext";
import Router from "next/router";
import { Spin } from "antd";

const PrivateRoute = (AuthComponent) => {
  function PrivateComponent({ children }) {
    const { authenticated, loading, user } = useContext(AuthContext);

    useEffect(() => {
      if (authenticated == null) {
        return;
      }
      if (!authenticated) {
        Router.push("/login");
      }
    }, [authenticated, user]);

    if (loading) {
      return (
        <div className="loader">
          <Spin size="large" className="loader" />
        </div>
      );
    }
    return <>{children}</>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <AuthComponent {...this.props} />
        </PrivateComponent>
      );
    }
  };
};

export default PrivateRoute;
