import { API } from "@project/shared";
import { Post } from "../interfaces/post";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import "dayjs/locale/ja";

import DefaultAvatarImage from "../assets/default-avatar.jpg";
import i18next from "i18next";

dayjs.extend(relativeTime);

const getLanguage = () => i18next.language || window.localStorage.i18nextLng;

dayjs.locale(getLanguage());

export const addPost = async (formData): Promise<any> => {
  return API.post(`/post`, formData);
};

export const fetchAllPost = async () => {
  const res = await API.get("/post");
  if (res) {
    return res?.data;
  }
};
export const fIndPostByID = async (id) => {
  return API.get(`/post/${id}`);
};

export const getClubNewsFeed = async (page) => {
  const pageNumber = page.pageParam || 1;

  const data: any = await API.get(
    `/newsfeed/club?page=${pageNumber}&pageSize=10`
  );

  const dataList = [];

  data?.data.forEach((singleData: Post) => {
    //Comment Parse
    const comments = [];
    singleData.comments?.map((comment) => {
      if (comment) {
        const replies = [];

        comment.reply.map((reply) => {
          replies.push({
            id: reply.id,
            user_id: reply.user_id,
            replied_to: reply.replied_to,
            avatar: reply.profile_image || DefaultAvatarImage,
            name: reply.username,
            comment: reply.description,
            time: dayjs(reply.created_at).fromNow(),
            user_type: reply.user_type,
          });
        });
        comments.push({
          id: comment.id,
          user_id: comment.user_id,
          post_id: comment.post_id,
          name: comment.username,
          comment: comment.description,
          avatar: comment.profile_image || DefaultAvatarImage,
          time: dayjs(comment.created_at).fromNow(),
          replies: replies,
          replyFormEnabled: false,
          user_type: comment.user_type,
        });
      }
    });

    //Image parse
    const imageList = [];
    singleData.image1 && imageList.push(singleData.image1);
    singleData.image2 && imageList.push(singleData.image2);
    singleData.image3 && imageList.push(singleData.image3);

    //Post Time to Dayjs
    const relativeTime = dayjs(singleData.created_at).fromNow();

    const newData = {
      id: singleData.id,
      profileImage: singleData.profile_image || DefaultAvatarImage,
      userId: singleData.user_id,
      time: relativeTime,
      name: singleData.username,
      images: imageList,
      description: singleData.description,
      comments: comments,
      postType: singleData.post_type,
    };

    dataList.push(newData);
  });

  return { data: dataList, count: data?.count };
};

export const getSponsorNewsFeed = async (page) => {
  const pageNumber = page.pageParam || 1;
  const data: any = await API.get(
    `/newsfeed/sponsor?page=${pageNumber}&pageSize=10`
  );

  const dataList = [];

  data?.data.forEach((singleData: Post) => {
    //Comment Parse
    const comments = [];
    singleData.comments?.map((comment) => {
      if (comment) {
        const replies = [];

        comment.reply.map((reply) => {
          replies.push({
            id: reply.id,
            user_id: reply.user_id,
            replied_to: reply.replied_to,
            avatar: reply.profile_image || DefaultAvatarImage,
            name: reply.username,
            comment: reply.description,
            time: dayjs(comment.created_at).fromNow(),
            user_type: reply.user_type,
          });
        });
        comments.push({
          id: comment.id,
          user_id: comment.user_id,
          post_id: comment.post_id,
          name: comment.username,
          comment: comment.description,
          avatar: comment.profile_image || DefaultAvatarImage,
          time: dayjs(comment.created_at).fromNow(),
          replies: replies,
          replyFormEnabled: false,
          user_type: comment.user_type,
        });
      }
    });

    //Image parse
    const imageList = [];
    singleData.image1 && imageList.push(singleData.image1);
    singleData.image2 && imageList.push(singleData.image2);
    singleData.image3 && imageList.push(singleData.image3);

    //Post Time to Dayjs
    const relativeTime = dayjs(singleData.created_at).fromNow();

    const newData = {
      id: singleData.id,
      profileImage: singleData.profile_image || DefaultAvatarImage,
      userId: singleData.user_id,
      time: relativeTime,
      name: singleData.username,
      images: imageList,
      description: singleData.description,
      comments: comments,
      postType: singleData.post_type,
    };
    dataList.push(newData);
  });

  return { data: dataList, count: data?.count };
};

export const sendComment = async (postData) => {
  const data = await API.post("/comment", postData);
  return data;
};

export const sendCommentReply = async (postData) => {
  const data = await API.post("/reply", postData);
  return data;
};

export const deletePost = async (id: string) => {
  await API.delete(`/post/${id}`);
};
